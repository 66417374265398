/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import Auth from "@aws-amplify/auth"
import config from "./config/website"
import "./src/assets/scss/style.scss"
import "./src/assets/scss/settings.scss"

Auth.configure({
  region: config.auth.region,
  userPoolId: config.auth.pool,
  userPoolWebClientId: config.auth.client,
  oauth: {
    domain: config.auth.domain,
    scope: [
      "phone",
      "email",
      "profile",
      "openid",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: `${window.location.origin}/challenge_success`,
    redirectSignOut: `${window.location.origin}/`,
    responseType: "code",
  },
  cookieStorage: {
    domain: `.${config.domain}`,
    secure: true,
    path: "/",
    expires: 365,
  },
})
