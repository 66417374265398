module.exports = {
  siteTitle: "Landing Pages | Way",
  siteTitleShort: "Way",
  siteAddress: new URL("https://way.live"),
  bucket: "way-site",
  domain: "way.live",
  appUrl: "https://app.way.live",
  appSignUpUrl: "https://app.way.live/auth?state=signUp",
  onboardingUrl: "https://onboarding.way.live",
  lang: "en",
  pathPrefix: "/",
  siteLogo: "images/logo.png",
  siteDescription:
    "Create custom landing pages that convert more visitors. No coding required.",
  author: "@way-live",
  organization: "Way",
  keywords: ["Landing Pages", "Leads"],

  fbAppID: "481818676081781",
  userTwitter: "@way", // Twitter Username
  ogSiteName: "Landing Pages | Way", // Facebook Site Name
  ogLanguage: "en_US",

  auth: {
    region: "us-east-1",
    pool: "us-east-1_Q5dUedOTR",
    client: "6ekffr1ersrruprb0ukajoaj3u",
    domain: "prod-way.auth.us-east-1.amazoncognito.com",
  },

  // Manifest and Progress color
  themeColor: "#FF5C33",
  backgroundColor: "#FF5C33",

  // Social component
  twitter: "https://twitter.com/way-live/",
  twitterHandle: "@way-live",
  github: "https://github.com/way-live/",
  linkedin: "https://www.linkedin.com/in/way-live/",
  youtube: "https://www.youtube.com/way-live",

  // Intercom
  intercomAppId: "ujhdbny4",

  // Crisp
  crispWebsiteId: "6fc5bfc6-95a2-46eb-bbda-041ce1446fdc",

  // AppSumo
  appSumoSignUpApiUrl: "https://app.way.live/api/appsumo/signup",

  // AWC Challenge
  challengeSignUpApiUrl: "https://app.way.live/api/challenge/signup",

  // StackCommerce
  stackCommerceSignUpApiUrl: "https://app.way.live/api/stackcommerce/signup",

  // Google Tag Manager
  gtmId: "GTM-KRPXS9G",

  // Facebook Verification
  facebookVerification: "k79jmsj82ri7b0hvbsa3rfjz6ncovh",
}
