module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"backgroundColor":"#fafafa","maxWidth":1035},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Landing Pages | Way","short_name":"Way","description":"Create custom landing pages that convert more visitors. No coding required.","start_url":"/","lang":"en","background_color":"#FF5C33","theme_color":"#FF5C33","display":"standalone","icons":[{"src":"/favicons/android-chrome-144x144.png","sizes":"144x144","type":"image/png"}],"localize":[{"start_url":"/pt/","lang":"pt","name":"Construtor de Landing Pages | Way","short_name":"Way","description":"Crie landing pages que convertem mais visitantes. Sem precisar de um desenvolvedor."}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","pt"],"defaultLanguage":"en","path":"/home/thiagozf/github/way-live/way-site/locales","i18nextOptions":{"debug":true,"lowerCaseLng":true,"saveMissing":false,"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/appsumo","languages":["en"]},{"matchPath":"/stackcommerce","languages":["en"]},{"matchPath":"/challenge","languages":["en"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KRPXS9G"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
